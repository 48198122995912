import React from "react";
import AboutCSS from './About.css';
import { useEffect } from 'react'
import { useTranslate } from '../../helpers/helpers'

function About() {

    const t = useTranslate();

    return (
        <section className="bg-[#173a32] about">
            <div className="flex flex-col md:grid md:grid-cols-3 md:text-center md:items-center text-center items-center justify-center gap-y-10 md:mx-10 md:h-80">
                <div className="my-10">
                    <span className="text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl text-white font-bold" style={{fontFamily: 'Work Sans,sans'}}>+1 <span className="lg:text-5xl">{t('about_title1')}</span></span>
                    <p className="text-white text-lg xl:text-xl 2xl:text-2xl mt-2 font-medium lg:text-center" style={{fontFamily: 'Work Sans'}}>{t('about_subtitle1')}</p>
                </div>

                <div>
                    <span className="text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl text-white font-bold" style={{fontFamily: 'Work Sans,sans'}}>+1000</span>
                    <p className="text-white text-lg xl:text-xl 2xl:text-2xl mt-2 font-medium" style={{fontFamily: 'Work Sans'}}>{t('about_subtitle2')}</p>
                </div>

                <div className="my-10">
                    <span className="text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl text-white font-bold" style={{fontFamily: 'Work Sans,sans'}}>+500</span>
                    <p className="text-white text-lg xl:text-xl 2xl:text-2xl mt-2 font-medium" style={{fontFamily: 'Work Sans'}}>{t('about_subtitle3')}</p>
                </div>
            </div>
        </section>
    )
}

export default About;