import React from "react";
import { Disclosure } from '@headlessui/react'
import Logo2 from '../../assets/img/Logo/Logo2.png'
import Flag from '../Flags/Flag'
import BandeiraBRA from '../../assets/bandeiras/brasil.png'
import BandeiraUSA from '../../assets/bandeiras/united-states.png'

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function Navbar() {
    return (
      <Disclosure as="nav" className="relative">
        <div className="px-10 py-4">
          <div className="relative flex h-16 items-center justify-between 2xl:my-5">
            
            <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img
                  alt="Your Company"
                  src={Logo2}
                  className="h-20 w-auto z-20"
                />
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

            <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center z-20">
              <Flag image={BandeiraBRA} language='pt'/>
              <Flag image={BandeiraUSA} language='en'/>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"></div>

            </div>
          </div>
        </div>
  
      </Disclosure>
    )
  }
