import React from "react";
import AtendimentosCSS from './Depoimentos.css';
import ImageDepo from '../../assets/img/Depoimento/ImageDepo.png'
import Brasil from '../../assets/img/Brasil/brazil 1.png';
import CardsDepo from "../Cards-Depo/CardsDepo";
import { useTranslate } from '../../helpers/helpers'

function Depoimentos() {

    const t = useTranslate();

    return (
        <section className="bg-white" style={{height: '30vh'}}>
            <div className="flex flex-col justify-center items-center text-center my-44">
                <h1 className="font-extrabold text-black text-3xl md:text-4xl xl:text-5xl" style={{fontFamily: 'Work Sans,sans'}}>{t('depo_title')}</h1>
                <h5 className="text-lg md:text-xl xl:text-2xl font-bold my-2" style={{fontFamily: 'Work Sans,sans', color: 'rgba(130, 130, 130, 1)'}}>{t('depo_subtitle')}</h5>
            </div>
        </section>
    )
}

export default Depoimentos;