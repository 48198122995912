import React from "react";
import './InfiniteSlider.css';
import IMG1 from '../../../assets/img/Depoimento/1.jpg'
import IMG2 from '../../../assets/img/Depoimento/Model2.png';
import IMG3 from '../../../assets/img/Depoimento/Model3.png';

export default function InfiniteSlider() {
    const cards = [
        {
            img: IMG1,
            name: "Daniel Bandeira",
            location: "João Pessoa - PB",
            text: "Ter o Thiago Nascimento como caminho para a recuperação da minha empresa foi o maior diferencial para sobreviver e crescer!",
            rating: "★★★★★"
        },
        {
            img: IMG1,
            name: "Daniel Bandeira",
            location: "João Pessoa - PB",
            text: "Ter o Thiago Nascimento como caminho para a recuperação da minha empresa foi o maior diferencial para sobreviver e crescer!",
            rating: "★★★★★"
        },
        {
            img: IMG1,
            name: "Daniel Bandeira",
            location: "João Pessoa - PB",
            text: "Ter o Thiago Nascimento como caminho para a recuperação da minha empresa foi o maior diferencial para sobreviver e crescer!",
            rating: "★★★★★"
        }
    ];

    return (
        <section style={{height: '80vh'}}>
        <div className="slider mb-40 overflow-x-hidden" style={{ '--width': '1500px', '--height': '21rem', '--quantity': cards.length,}}>
            <div className="list gap-x-10">
                {/* Repita os cards duas vezes para o loop */}
                {[...Array(2)].flatMap((_, idx) =>
                    cards.map((card, index) => (
                        <div key={`${idx}-${index}`} className="bg-[white] p-6 rounded-lg w-6/12 card-depo border border-gray-300 shadow pause" style={{ borderRadius: '18px' }}>
                            <div className="flex items-center">
                                <img className="w-20 h-20 rounded-full object-cover" src={card.img} alt="User" />
                                <div className="ml-4">
                                    <p className="text-black font-bold text-lg" style={{ fontFamily: 'Inter, sans-serif' }}>{card.name}</p>
                                    <p className="text-zinc-800 text-sm">{card.location}</p>
                                </div>
                            </div>
                            <div className="flex mt-10">
                                <p className="text-black mt-4 text-xl" style={{ fontFamily: 'Inter, sans-serif' }}>"{card.text}"</p>
                            </div>
                            <div className="flex justify-end mt-20">
                                <span className="text-yellow-500">{card.rating}</span>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
</section>
    );
}
