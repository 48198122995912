import React from "react";
import HeaderCSS from '../Header/Header.css'
import Porco from '../../assets/img/Header-Icons/Porco.png'
import Verificado from '../../assets/img/Header-Icons/Verificado.png'
import Balance from '../../assets/img/Header-Icons/Balance.png'
import Acute from '../../assets/img/Header-Icons/Acute.png'
import { useTranslate } from '../../helpers/helpers'
import { motion } from "framer-motion"

function CardsHeader () {
    
    const t = useTranslate();

    return (
       <section className="h-full my-10">
            <div className="flex flex-col sm:grid sm:grid-cols-2 lg:flex lg:flex-row px-10 gap-y-5 sm:gap-x-5 2xl:gap-x-14 2xl:px-44 2xl:mb-32">
            <motion.div  
            whileHover={{ scale: 1.1 }}
            onHoverStart={e => {}}
            onHoverEnd={e => {}}
            className="w-full flex flex-col justify-center items-center h-56 sm:mt-10 lg:-mt-40 transition-colors duration-300 bg-[#173a32] hover:bg-[#1A4F2A] z-2" 
            style={{borderRadius: '15px'}}
            >
                <div className="flex flex-col items-center text-center">    
                        <img className="w-16 2xl:w-20" src={Porco} alt="" />
                                    
                        <h5 className="mb-1 text-xl font-bold my-5 text-white" style={{fontFamily: 'Work Sans, sans'}}>{t('header_cards1')}</h5>
                    </div>
                </motion.div>

            <motion.div 
            whileHover={{ scale: 1.1 }}
            onHoverStart={e => {}}
            onHoverEnd={e => {}} 
            className="w-full flex flex-col justify-center items-center h-56 sm:mt-10 lg:-mt-40 transition-colors duration-300 bg-[#173a32] hover:bg-[#1A4F2A] z-2" 
            style={{borderRadius: '15px'}}
            >
                <div className="flex flex-col items-center text-center">    
                        <img className="w-16 2xl:w-20" src={Verificado} alt="" />
                                    
                        <h5 className="mb-1 text-xl font-bold my-5 text-white" style={{fontFamily: 'Work Sans, sans'}}>{t('header_cards2')}</h5>
                    </div>
                </motion.div>

            <motion.div
            whileHover={{ scale: 1.1 }}
            onHoverStart={e => {}}
            onHoverEnd={e => {}}   
            className="w-full flex flex-col justify-center items-center h-56 lg:-mt-40 transition-colors duration-300 bg-[#173a32] hover:bg-[#1A4F2A] z-2" 
            style={{borderRadius: '15px'}}
            >
                <div className="flex flex-col items-center text-center">    
                        <img className="w-16 2xl:w-20" src={Balance} alt="" />
                                    
                        <h5 className="mb-1 text-xl font-bold my-5 text-white" style={{fontFamily: 'Work Sans, sans'}}>{t('header_cards3')}</h5>
                    </div>
                </motion.div>

            <motion.div  
            whileHover={{ scale: 1.1 }}
            onHoverStart={e => {}}
            onHoverEnd={e => {}} 
            className="w-full flex flex-col justify-center items-center h-56 lg:-mt-40 transition-colors duration-300 bg-[#173a32] hover:bg-[#1A4F2A] z-2" 
            style={{borderRadius: '15px'}}
            >
                <div className="flex flex-col items-center text-center">    
                        <img className="w-16 2xl:w-20" src={Acute} alt="" />
                                    
                        <h5 className="mb-1 text-xl font-bold my-5 text-white" style={{fontFamily: 'Work Sans, sans'}}>{t('header_cards4')}</h5>
                    </div>
                </motion.div>
            </div>


            
       </section>
    )
}

export default CardsHeader;