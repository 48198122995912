import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// Helper para alterar o título da página
export const usePageTitle = (title) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [location, title]);
};

// Helper para alterar o meta description
export const updateMetaDescription = (description) => {
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', description);
  }
};

// Helper para tradução
export const useTranslate = () => {
  const { t } = useTranslation();
  return t;
};


// Helper para mudar o idioma
export const changeLanguage = (language) => {
  i18n.changeLanguage(language); // Muda o idioma ativo no i18n
};

// Helper para enviar mensagem via WhatsApp
export const sendWhatsAppMessage = (message) => {
  const phoneNumber = '5511942467936'; // Substitua pelo número de destino
  const defaultMessage = 'Olá, vim pelo site!'; // Mensagem padrão
  
  // Verifica se a mensagem é uma string, caso contrário, usa a mensagem padrão
  const finalMessage = typeof message === 'string' ? message : defaultMessage;
  
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(finalMessage)}`;
  window.open(url, '_blank');
};

// Outros helpers globais
export const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

