import React from "react";
import LineCards from '../../assets/img/Line/Line-Cards/Line-Cards.png'
import Globo from '../../assets/img/Diferenciais-icons/Globo.png'
import User from '../../assets/img/Diferenciais-icons/User.png'
import Locker from '../../assets/img/Diferenciais-icons/Locker.png'
import Group from '../../assets/img/Diferenciais-icons/Group.png'
import Home from '../../assets/img/Diferenciais-icons/Home.png'
import Brasil2 from '../../assets/img/Brasil/Brasil2.png'
import { useTranslate } from '../../helpers/helpers'
import { motion } from "framer-motion"


function CardsDiferenciais() {

    const t = useTranslate();

    return (
    <section className="">
        <div className="flex flex-col md:grid md:grid-cols-2 xl:grid xl:grid-cols-3 md:gap-x-10 xl:gap-x-20 2xl:mx-32 gap-y-10 px-10">

        <motion.div 
                whileHover={{ scale: 0.9 }}
                onHoverStart={e => {}}
                onHoverEnd={e => {}}
                className="flex flex-col items-start text-start h-96 transition-colors duration-300 bg-[#C9A66A] hover:bg-[#947541]" style={{borderRadius: '15px'}}>    
                        <img className="w-7 mx-10 mt-4" src={Globo} alt="" />
                                    
                        <div className="w-full px-10">          
                            <h5 className="mb-1 text-3xl font-bold my-5 mt-10 text-white" style={{fontFamily: 'Work Sans, sans'}}>{t('diferenciais_cards_title1')}<br/>{t('diferenciais_cards_title2')}</h5>
                            <p className="text-white text-md font-medium lg:w-72" style={{fontFamily: 'Work Sans,sans'}}>{t('diferenciais_cards_subtitle1')}</p>
                            <img className="mt-10 w-7/12" src={LineCards} alt="" />
                            
                            {/* Wrapper para garantir largura total */}
                            <div className="w-full mt-5">
                                <button className="bg-white text-[#C9A66A] w-full font-bold text-lg 2xl:text-xl" style={{height: '2.5rem', fontFamily: 'Work Sans,sans'}}>{t('diferenciais_button')}</button>
                            </div>
                        </div>
                    </motion.div>
                <motion.div 
                whileHover={{ scale: 0.9 }}
                onHoverStart={e => {}}
                onHoverEnd={e => {}}
                className="flex flex-col items-start text-start h-96 transition-colors duration-300 bg-[#C9A66A] hover:bg-[#947541]" style={{borderRadius: '15px'}}>    
                        <img className="w-7 mx-10 mt-4" src={User} alt="" />
                                    
                        <div className="w-full px-10">          
                            <h5 className="mb-1 text-3xl font-bold my-5 mt-10 text-white" style={{fontFamily: 'Work Sans, sans'}}>{t('diferenciais_cards_title3')}<br/>{t('diferenciais_cards_title4')}</h5>
                            <p className="text-white text-md font-medium lg:w-72" style={{fontFamily: 'Work Sans,sans'}}>{t('diferenciais_cards_subtitle2')}</p>
                            <img className="mt-10 w-7/12" src={LineCards} alt="" />
                            
                            {/* Wrapper para garantir largura total */}
                            <div className="w-full mt-5">
                                <button className="bg-white text-[#C9A66A] w-full font-bold text-lg 2xl:text-xl" style={{height: '2.5rem', fontFamily: 'Work Sans,sans'}}>{t('diferenciais_button')}</button>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div 
                    whileHover={{ scale: 0.9 }}
                    onHoverStart={e => {}}
                    onHoverEnd={e => {}}
                    className="flex flex-col items-start text-start transition-colors duration-300 bg-[#C9A66A] hover:bg-[#947541] h-96" style={{borderRadius: '15px'}}>    
                        <img className="w-7 mx-10 mt-4" src={User} alt="" />
                                    
                        <div className="w-full px-10">   
                            <h5 className="mb-1 text-3xl font-bold my-5 mt-10 text-white" style={{fontFamily: 'Work Sans, sans'}}>{t('diferenciais_cards_title5')}<br/>{t('diferenciais_cards_title6')}</h5>
                            <p className="text-white text-md font-medium" style={{fontFamily: 'Work Sans,sans'}}>{t('diferenciais_cards_subtitle3')}</p>
                            <img className="mt-10 w-7/12" src={LineCards} alt="" />
                            
                            {/* Wrapper para garantir largura total */}
                            <div className="w-full mt-5">
                                <button className="bg-white text-[#C9A66A] w-full font-bold text-lg 2xl:text-xl" style={{height: '2.5rem', fontFamily: 'Work Sans,sans'}}>{t('diferenciais_button')}</button>
                            </div>
                        </div>
                    </motion.div>


                    <motion.div 
                    whileHover={{ scale: 0.9 }}
                    onHoverStart={e => {}}
                    onHoverEnd={e => {}}
                    className="flex flex-col items-start text-start h-96 transition-colors duration-300 bg-[#C9A66A] hover:bg-[#947541]" style={{borderRadius: '15px'}}>    
                        <img className="w-7 mx-10 mt-4" src={User} alt="" />
                                    
                        <div className="w-full px-10">          
                            <h5 className="mb-1 text-3xl font-bold my-5 mt-10 text-white" style={{fontFamily: 'Work Sans, sans'}}>{t('diferenciais_cards_title7')}<br/>{t('diferenciais_cards_title8')}</h5>
                            <p className="text-white text-md font-medium" style={{fontFamily: 'Work Sans,sans'}}>{t('diferenciais_cards_subtitle3')}</p>
                            <img className="mt-10 w-7/12" src={LineCards} alt="" />
                            
                            {/* Wrapper para garantir largura total */}
                            <div className="w-full mt-5">
                                <button className="bg-white text-[#C9A66A] w-full font-bold text-lg 2xl:text-xl" style={{height: '2.5rem', fontFamily: 'Work Sans,sans'}}>{t('diferenciais_button')}</button>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div 
                    whileHover={{ scale: 0.9 }}
                    onHoverStart={e => {}}
                    onHoverEnd={e => {}}
                    className="flex flex-col items-start text-start h-96 transition-colors duration-300 bg-[#C9A66A] hover:bg-[#947541]" style={{borderRadius: '15px'}}>    
                        <img className="w-7 mx-10 mt-4" src={User} alt="" />
                                    
                        <div className="w-full px-10">          
                            <h5 className="mb-1 text-3xl font-bold my-5 mt-10 text-white" style={{fontFamily: 'Work Sans, sans'}}>{t('diferenciais_cards_title9')}<br/>{t('diferenciais_cards_title10')}</h5>
                            <p className="text-white text-md font-medium" style={{fontFamily: 'Work Sans,sans'}}>{t('diferenciais_cards_subtitle3')}</p>
                            <img className="mt-10 w-7/12" src={LineCards} alt="" />
                            
                            {/* Wrapper para garantir largura total */}
                            <div className="w-full mt-5">
                                <button className="bg-white text-[#C9A66A] w-full font-bold text-lg 2xl:text-xl" style={{height: '2.5rem', fontFamily: 'Work Sans,sans'}}>{t('diferenciais_button')}</button>
                            </div>
                        </div>
                    </motion.div>

        </div>
    </section>
   
)
}


export default CardsDiferenciais;