import React from "react";
import ThiagoCSS from './Thiago.css';
import Thiagoimg from '../../assets/img/Thiago/Thiago.jpg'
import { useTranslate } from '../../helpers/helpers'

function Thiago() {

    const t = useTranslate();

    return (
        <section className="main-thiago" style={{backgroundImage: `url(${Thiagoimg})`, backgroundSize: 'cover', backgroundPosition: 'center 20%', backgroundRepeat: 'no-repeat', position: 'relative'}}>
             <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Preto com 50% de opacidade
                    zIndex: 1
                }}
                ></div>

                <div className="flex flex-col justify-center items-center text-start md:items-start md:mx-14 lg:mx-20 2xl:mx-40 md:text-center h-full" style={{fontFamily: 'Work Sans,sans'}}>
                    <div className="z-20">
                        <h1 className="text-white font-bold text-4xl lg:text-5xl ">+{t('thiago1')}</h1>
                        <h1 className="text-white font-bold text-4xl lg:text-5xl ">+{t('thiago2')}</h1>
                        <h1 className="text-white font-bold text-4xl lg:text-5xl ">+{t('thiago3')}</h1>
                    </div>
                </div>
        </section>
    )
}

export default Thiago;



// <div
// style={{
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)', // Preto com 50% de opacidade
//     zIndex: 1
// }}
// ></div>

// style={{backgroundImage: `url(${Thiagoimg})`, backgroundSize: 'cover', backgroundPosition: 'center 20%', backgroundRepeat: 'no-repeat', position: 'relative'}}