import './App.css';
import Navbar from './components/Navbar/Navbar'
import Header from './components/Header/Header';
import CardsHeader from './components/Cards-Header/CardsHeader';
import About from './components/About/About';
import Depoimentos from './components/Depoimentos/Depoimentos';
import InfiniteSlider from './components/Depoimentos/Infinite-Slider/InfiniteSlider'
import Brasil from './components/Brasil/Brasil'
import Thiago from './components/Thiago/Thiago';
import Formulario from './components/Formulário/Formulario';
import Solutions from './components/Solutions/Solutions';
import Diferenciais from './components/Diferenciais/Diferenciais';
import CardsDiferenciais from './components/Cards-Diferenciais/Cards-Diferenciais';
import Footer from './components/Footer/Footer'
import FloatActionButton from './components/Float-Action-Button/FloatActionButton'

const App = () => {

  return (
    
    <>
    <FloatActionButton/>
    <Header/>
    <CardsHeader/>
    <About/>
    <Diferenciais/>
    <CardsDiferenciais/>
    <Brasil/>
    <Thiago/>
    <Depoimentos/>
    <InfiniteSlider/> 
    <Formulario/>
    <Footer/>
    
    
    </>
  );
}

export default App;
