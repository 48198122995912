const en = {
    translation: {
      header_title: "Proffit accounting",
      header_subtitle: "Our experienced team offers integrated solutions to overcome any business challenge.",
      header_button: 'Lets transform results',
      
      
      header_cards1: 'Financial Planning',
      header_cards2: 'Specialized Consulting',
      header_cards3: 'Accounting Security',
      header_cards4: 'Resource Management',

      about_title1: 'milion',  
      about_subtitle1: 'in invoices generated monthly',
      about_subtitle2: 'assisted companies',
      about_subtitle3: 'NRLEs generated', 

      diferenciais_title: 'We have the ideal solution for you',
      diferenciais_cards_title1: 'Opening of',
      diferenciais_cards_title2: 'NRLE',
      diferenciais_cards_title3: 'Accounting',
      diferenciais_cards_title4: 'Advice',
      diferenciais_cards_title5: 'Financial',
      diferenciais_cards_title6: 'Statements',
      diferenciais_cards_title7: 'Succession',
      diferenciais_cards_title8: 'Planning',
      diferenciais_cards_title9: 'Family',
      diferenciais_cards_title10: 'Inventory',
      diferenciais_cards_subtitle1: 'NRLE opening monitoring/planning with extreme ease',
      diferenciais_cards_subtitle2: 'Accounting monitoring and planning for your bussines.',
      diferenciais_cards_subtitle3: 'Issuance and monitoring of main statements.',
      diferenciais_cards_subtitle4: 'Clear inheritance, with divisions without conflict.',
      diferenciais_button: 'To know',

      brasil_title: 'Accounting for companies throughout Brazil',
      brasil_subtitle: 'Serving companies throughout the national territory with online service, and in-person services in João Pessoa and the region.',
      brasil_button: 'Put your company on the map',

      thiago1: 'RELIABLE',
      thiago2: 'INNOVATIVE',
      thiago3: 'EFFICIENT',

      depo_title: 'Trust on us',
      depo_subtitle: 'See some of who makes history with us',

      forms_title: 'Shall we talk a little?',
      forms_subtitle: 'We want to hear from you!',
      forms_name: 'Name',
      forms_email: 'E-mail',
      forms_phone: 'Phone',
      forms_cnpj: 'Already have NRLE?',
      forms_cont: 'Accounting migration?',
      forms_speak: 'Tell us a little more...(Optional)',
      forms_button: 'Send',

      footer_title1: 'Come see us',
      footer_title2: 'Find us on social media',
      footer_title3: 'Specialized in companies',
      footer_rua: 'S.Josita Almeida, 240, Office 06 Altiplano',
      footer_cidade: 'João Pessoa - PB',
      footer_cep: 'Zip Code: 58046-490',
      footer_phone: 'Phone',
      footer_button: 'Talk to us',
      footer_copyright: '© 2024 All Rights Reserved | NRLE: xx.xxx.xxxx/xxxx-xx',
      footer_dev: 'Developed by Flag Lab'
    }
  };
  
  export default en;

