import React from "react";
import Logo from '../../assets/img/Logo/Logo2.png'
import Line from '../../assets/img/Line/Line 1.png'
import Icon1 from '../../assets/img/Social-Items/Instagram.png'
import './Footer.css'; 
import { useTranslate } from '../../helpers/helpers'
import { motion } from "framer-motion";

const Footer = () => {

  const t = useTranslate();

  return (
   

<footer class="bg-[#173a32]">
    <div class="mx-auto w-full max-w-screen-2xl p-4 py-6 lg:py-8">
        <div class="md:flex md:justify-between">
          <div class="mb-6 md:mb-0">
              <img src={Line} alt="" />
              <img className="w-20" src={Logo} alt="" />
          </div>
          <div class="grid grid-cols-1 text-center gap-8 md:gap-6 md:grid-cols-2 lg:grid-cols-3">
              <div className="md:text-start lg:text-start lg:mx-5">
                 <h1 className="font-bold text-2xl md:text-2xl text-white">{t('footer_title1')}</h1>

                 <div className="">
                  <p className="text-sm md:text-sm text-white font-normal my-3">{t('footer_rua')}</p>
                  <p className="text-sm md:text-sm text-white font-normal my-3">{t('footer_cidade')}</p>
                  <p className="text-sm md:text-sm text-white font-normal">{t('footer_cep')}</p>                  
                 </div>
              </div>

              <div>
                <h1 className="font-bold text-2xl md:text-start lg:text-start text-white">{t('footer_title2')}</h1>

                <a href="https://www.instagram.com/thiagonascimentocontabilidade/"><img className="my-4 mx-auto mx-md-0" src={Icon1} alt="" /></a>
              </div>

              <div className="mb-10 md:text-start lg:text-start">
                <h1 className="font-bold text-2xl text-white">{t('footer_title3')}</h1>

                <p className="text-sm md:text-xs text-white font-normal my-3">{t('footer_phone')}: (83) 9 XXXX-XXXX</p>

                <div className="px-10 md:px-0">
                  <motion.button 
                   whileHover={{ scale: 1.1 }}
                   whileTap={{scale: 0.9}}
                   onHoverStart={e => {}}
                   onHoverEnd={e => {}}
                  className="text-black font-bold text-xl md:text-lg w-full" 
                  style={{backgroundColor: 'white', height: '4.5vh'}}>{t('footer_button')}
                  </motion.button>
                </div>
              </div>
          </div>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div class="text-center md:flex md:items-center md:justify-between">
          <span class="text-sm text-white sm:text-center lg:text-xs dark:text-gray-400">{t('footer_copyright')}
          </span>
          <div class="flex justify-center mt-2 sm:justify-center sm:mt-2">
              <p className="text-sm lg:text-xs text-white">{t('footer_dev')}</p>
          </div>
      </div>
    </div>
</footer>

  );
};

export default Footer;