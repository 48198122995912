import React from "react"
import FloatActionButtonCSS from './FloatActionButton.css'
import { FaArrowUp } from "react-icons/fa";

export default function FloatActionButton () {

      const handleScrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', 
        });
      };

    return (
      <div
      className="group fixed bottom-0 right-0 p-2 flex items-end justify-end w-24 h-24 z-20"
      style={{ cursor: 'pointer' }}
      onClick={handleScrollToTop}
    >
      <div className="text-white shadow-xl flex items-center justify-center p-4 mx-3 my-4 rounded-full bg-black z-50 absolute hid">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 -2 16 20"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-3"
        >
          <FaArrowUp />
        </svg>
      </div>
    </div>
    )
}

