import React from 'react'
import BrasilCSS from './Brasil.css'
import Brasil2 from '../../assets/img/Brasil/Brasil2.png'
import { useTranslate } from '../../helpers/helpers'
import { motion } from "framer-motion"

function Brasil() {

    const t = useTranslate();
    
    return (
        <section className='bg-white'>
            <div className='flex flex-col md:grid md:grid-cols-2 justify-center items-center text-center px-10 my-32'>
                <div>
                    <img className='md:max-w-96 lg:max-w-md xl:max-w-lg 2xl:max-w-xl' src={Brasil2} alt="" />
                </div>

                <div>
                    <h1 className='text-3xl sm:text-4xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-black md:text-end mt-10' style={{color: 'rgba(130, 130, 130, 1)'}}>{t('brasil_title')}</h1>

                    <h5 className='text-md sm:text-xl md:text-lg lg:text-xl font-light md:text-end my-5' style={{color: 'rgba(130, 130, 130, 1)'}}>{t('brasil_subtitle')}</h5>

                    <motion.button 
                    whileHover={{ scale: 1 }}
                    whileTap={{scale: 0.9}}
                    onHoverStart={e => {}}
                    onHoverEnd={e => {}}
                    className='text-white text-md lg:text-xl w-full font-bold mt-14' style={{height: '3.5rem' ,borderRadius: '8px' ,fontFamily: 'Work Sans,sans', backgroundColor: 'black'}}>{t('brasil_button')}
                    </motion.button>
                </div>
            </div>
        </section>
    )
}

export default Brasil;