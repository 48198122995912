import React from "react";
import FormularioCSS from './Formulario.css';
import Person from '../../assets/img/Forms-Img/Person.png'
import { useTranslate } from '../../helpers/helpers'
import { motion } from "framer-motion"

function Formulario() {

    const t = useTranslate();

    return (
        <section className="forms">
           <div className="container">
           <div className="container-fluid">
                <div className="row d-flex align-items-center">
                    <div className="col-sm-12 col-forms">
                        <div className="card justify-content-center card-forms shadow-lg">
                            <div className="row d-flex row-gap-1">

                                <div className="col-sm-6 p-5 col-forms">
                                    <div className="mb-3">
                                        <label className="form-label fw-bold title-user-form">{t('forms_name')}</label>
                                        <input type="text" className="form-control shadow-sm placeholder-forms" placeholder="Digite seu nome..." />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label fw-bold title-user-form">{t('forms_email')}</label>
                                        <input type="email" className="form-control shadow-sm placeholder-forms" placeholder="Digite seu e-mail..." />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label fw-bold title-user-form">{t('forms_phone')}</label>
                                        <input type="tel" className="form-control shadow-sm placeholder-forms" placeholder="Ex: (XX) 9 XXXX-XXXX" />
                                    </div>

                                    <div className="row d-flex align-items-center">
                                        <div className="col-sm-6">
                                            <label className="form-label fw-bold title-user-form">{t('forms_cnpj')}</label>
                                            <input type="text" className="form-control shadow-sm placeholder-forms" placeholder="Sim" />
                                        </div>

                                        <div className="col-sm-6">
                                            <label className="form-label fw-bold title-user-form">{t('forms_cont')}</label>
                                            <input type="text" className="form-control shadow-sm placeholder-forms" placeholder="Sim" />
                                        </div>
                                    </div>    

                                    <div className="mb-3 mt-3">
                                        <label className="form-label fw-bold title-user-form">{t('forms_speak')}</label>
                                        <textarea className="form-control shadow-sm placeholder-forms" rows="8"/>
                                    </div>


                                    <motion.button 
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{scale: 0.9}}
                                    onHoverStart={e => {}}
                                    onHoverEnd={e => {}}
                                    className="btn w-100 mt-5 btn-dark fw-bold fs-5 button-forms">{t('forms_button')}
                                    </motion.button>

                                </div>

                                
                                
                                                           
                            
                                <div className="col-sm-6 p-5 order-xs-1 order-sm-1"> 
                                    <div>
                                        <h6 className="fw-bold text-center fs-1 text-black title-forms">{t('forms_title')}</h6>

                                        <p className="text-center fs-6 text-secondary subtitle-forms">{t('forms_subtitle')}</p>

                                        <img className="max-w-52 md:max-w-60 lg:max-w-72 xl:max-w-80 2xl:max-w-full rounded mx-auto d-block" src={Person} alt="" />
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
           </div>
           </div>
        </section>
    )
};


export default Formulario;