const pt = {
  translation: {
    header_title: "Contabilidade de resultados",
    header_subtitle: "Nossa equipe experiente oferece soluções integradas para superar qualquer desafio empresarial.",
    header_button: 'Vamos transformar resultados',

    header_cards1: 'Planejamento Financeiro',
    header_cards2: 'Acessoria Especializada',
    header_cards3: 'Segurança Contábil',
    header_cards4: 'Gestão de Recursos',

    about_title1: 'milhão',  
    about_subtitle1: 'em notas fiscais geradas mensalmente',
    about_subtitle2: 'empresas assistidas',
    about_subtitle3: 'CNPJs gerados',  

    diferenciais_title: 'Temos a solução ideal para você',
    diferenciais_cards_title1: 'Abertura de',
    diferenciais_cards_title2: 'CNPJ',
    diferenciais_cards_title3: 'Assessoria',
    diferenciais_cards_title4: 'Contábil',
    diferenciais_cards_title5: 'Demonstrativos',
    diferenciais_cards_title6: 'Financeiros',
    diferenciais_cards_title7: 'Planejamento',
    diferenciais_cards_title8: 'Sucessório',
    diferenciais_cards_title9: 'Inventário',
    diferenciais_cards_title10: 'Familiar',
    diferenciais_cards_subtitle1: 'Acompanhamento/planejamento de abertura de CNPJ',
    diferenciais_cards_subtitle2: 'Acompanhamento e planejamento contábil.',
    diferenciais_cards_subtitle3: 'Emissão e acompanhamento dos principais demonstrativos.',
    diferenciais_cards_subtitle4: 'Herança clara, com divisões sem conflito.',
    diferenciais_button: 'Conhecer',
    
    
    brasil_title: 'Contabilidade para empresas em todo o Brasil',
    brasil_subtitle: 'Atendendo empresas em todo o território nacional com atendimento online, e na modalidade presencial atendimentos em João Pessoa e região.',
    brasil_button: 'Coloque sua empresa no mapa',

    thiago1: 'CONFIÁVEL',
    thiago2: 'INOVADORA',
    thiago3: 'EFICIENTE',

    depo_title: 'Confiam em nós',
    depo_subtitle: 'Veja um pouco de quem faz história conosco',

    forms_title: 'Vamos falar um pouco?',
    forms_subtitle: 'Queremos ouvir você!',
    forms_name: 'Nome',
    forms_email: 'E-mail',
    forms_phone: 'Telefone',
    forms_cnpj: 'Já possui CPNJ?',
    forms_cont: 'Migração de contabilidade?',
    forms_speak: 'Fale um pouco mais...(Opcional)',
    forms_button: 'Enviar',

    footer_title1: 'Venha nos ver',
    footer_title2: 'Encontre-nos nas redes sociais',
    footer_title3: 'Especializados em empresa',
    footer_rua: 'R.Josita Almeida, 240, Escritório 06 Altiplano',
    footer_cidade: 'João Pessoa - PB',
    footer_cep: 'CEP: 58046-490',
    footer_phone: 'Telefone',
    footer_button: 'Fale com a gente',
    footer_copyright: '© 2024 Todos os Direitos Reservados | CNPJ: xx.xxx.xxxx/xxxx-xx',
    footer_dev: 'Desenvolvido por Flag Lab'

  }
};

export default pt;