import React from "react";
import HeaderCSS from './Header.css'
import CardsHeader from "../Cards-Header/CardsHeader";
import ThiagoPrincipal from '../../assets/img/Thiago-Principal/Thiago-Principal.png'
import Navbar from '../Navbar/Navbar'
import { useTranslate } from "../../helpers/helpers";
import { motion } from "framer-motion"

function Header() {

    const t = useTranslate(); 

    return (
        <section className="row-header">
            <Navbar/>

            <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.4)', // Preto com 50% de opacidade
                zIndex: 0,
            }}
        ></div> 

            <div className="flex flex-col items-center justify-center md:items-start md:justify-center h-96 md:px-14 my-10" style={{position: 'relative' ,zIndex: '1'}}>
                <h1 className="text-white font-bold text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl md:w-5/12 xl:w-4/12 2xl:w-4/12 xl:mt-10" style={{fontFamily: 'Work Sans, sans'}}>{t('header_title')}</h1>

                <h5 className="text-white subtitle font-normal text-md lg:text-lg my-3 sm:mx-20 md:mx-0 md:w-6/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12" style={{fontFamily: 'Inter, sans'}}>{t('header_subtitle')}</h5>

                <motion.button 
                whileHover={{ scale: 1.1 }}
                whileTap={{scale: 0.9}}
                onHoverStart={e => {}}
                onHoverEnd={e => {}}
                className="w-10/12 waves-effect waves-light btn md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-4/12 mt-20 md:mt-10 text-white font-bold text-lg lg:text-xl" 
                style={{fontFamily: 'Anek Latin,sans-serif', borderRadius: '10px', height: '4.4rem', backgroundColor: '#C9A66A'}}
                >
                    {t('header_button')}
                </motion.button> 

            </div>
        </section>
    )
}


export default Header;
