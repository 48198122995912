import React from "react";
import DiferenciaisCSS from './Diferenciais.css';
import CardsDiferenciais from '../Cards-Diferenciais/Cards-Diferenciais.jsx'
import { useTranslate } from '../../helpers/helpers'

function Diferenciais() {

    const t = useTranslate();

    return( 
        <section className="bg-white">
            <div className="flex items-center justify-center text-center lg:justify-start my-10 md:my-0 md:p-20 md:mx-10">
                <h1 className="text-black text-2xl px-10 md:px-0 md:text-3xl lg:text-4xl font-extrabold" style={{fontFamily: 'Work Sans,sans'}}>{t('diferenciais_title')}</h1>
            </div>
        </section>
    )
}

export default Diferenciais;